<template>
	<TeaserImageAndText
		:component="teaser"
		:dark="dark"
	/>
</template>

<script>
export default defineNuxtComponent({
	props: {
		component: {
			type: Object,
		},
		dark: {
			type: Boolean,
		},
	},

	computed: {
		teaser() {
			const content = this.component.content;
			content.horizontal = true;
			return { content };
		},
	},
});
</script>
